import React from 'react'

import { Grid } from '@material-ui/core'

export default function Row({
  children,
  className,
  justify,
  spacing,
  spreadOut,
  ignoreDefaultStyle,
}) {
  const style = {
    alignItems: 'center',
  }

  if (!ignoreDefaultStyle) {
    style.marginTop = 5
    style.padding = 7.5
  }

  if (spreadOut) {
    style.justifyContent = 'space-evenly'
  }

  return (
    <Grid
      container
      className={className}
      justify={justify}
      spacing={spacing}
      style={style}
    >
      {children}
    </Grid>
  )
}
