import _ from 'lodash'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Avatar, Grid, Typography, Box } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {},
  section: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    //conditional
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  quote: {
    fontSize: 'xxx-large',
    fontWeight: '800',
    marginBottom: '-60px',
    marginleft: '100px',
    fontFamily: 'Karla',
  },
  quoteBottom: {
    fontSize: 'xxx-large',
    fontWeight: '800',
    marginTop: '-40px',
    marginleft: '100px',
    fontFamily: 'Karla',
    textAlign: 'end',
  },
  main: {
    backgroundColor: 'white',
    borderRadius: '0 50px',
    boxShadow: '0 0 20px 2px rgba(227, 227, 227, 0.9 )',
    padding: theme.spacing(2),
    //conditional
    paddingRight: '100px',
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(2),
    },
  },
  avatar: {
    height: '100px',
    width: '100px',
    boxShadow: '0 0 20px 2px rgba(227, 227, 227, 0.9 )',
    margin: theme.spacing(1),
    '& img': {
      objectFit: 'contain',
    },
  },
  box: {
    alignItems: 'center',
    textAlign: '-webkit-center',
    display: 'flex',
    //conditional
    flexDirection: 'row',
    marginLeft: '-60px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginLeft: '10px',
    },
  },
  testimonialName: {
    fontWeight: 600,
  },
  testimonialTitle: {
    fontSize: 'sm',
    color: '#6e6e6e',
  },
  testimonialBody: {
    maxWidth: '800px',
    padding: theme.spacing(2),
    fontSize: 'md',
    //conditional
    textAlign: 'center',
  },
}))

export default function WithLargeQuote() {
  const classes = useStyles()

  return (
    <Grid className={classes.container}>
      <Grid className={classes.section}>
        <Grid className={classes.main}>
          {/* <Typography className={classes.quote}>"</Typography> */}
          <Typography className={classes.testimonialBody}>
            Working with Made To Uplift <b>made my life easier</b> by saving me
            so much time! They created an amazing design for our sticker
            campaign, helped spread awareness around Out Youth's mission, and
            most importantly,
            <b>raised more money than I ever thought was possible</b> through a
            simple sticker campaign. I was blown away when I saw how many
            stickers we sold and how much money we raised just within one month
            of running the campaign. If you are looking for an easy, creative
            fundraising campaign that adds brand awareness to your non-profit, I{' '}
            <b>would highly suggest using Made to Uplift</b>. The team there is
            helpful, quick to respond, and delivered stronger results than I
            have ever been able to do on my own.
          </Typography>
          {/* /<Typography className={classes.quoteBottom}>"</Typography> */}
        </Grid>
        <Grid>
          <Box className={classes.box}>
            <Avatar
              className={classes.avatar}
              src={
                'https://user-content.givegab.com/uploads/group/logo/459294/Out%2BYouth.jpg'
              }
              alt={'Out Youth Logo'}
              mb={2}
            />
            <Grid>
              <Typography className={classes.testimonialName}>
                Lane Strickland{' '}
              </Typography>
              <Typography className={classes.testimonialTitle}>
                Out Youth Development Director
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
