import _ from 'lodash'
import moment from 'moment'

import REST from '../../utils/rest'

const DayOne = moment('2021-06-08')
const DayNum = date => {
  date = moment(date)
  const elapsed = moment.duration(date.diff(DayOne))
  return elapsed.days() + 1
}

const normalize = (value, min, max) => {
  return (value - min) / (max - min)
}

export default class CaseStudy {
  constructor() {
    this.orders = {
      nonprofit: 0,
      madetouplift: 0,
    }
  }

  async attribution() {
    return REST.post('campaign/breakdown/attribution', {
      ID: 1,
    })
  }

  get bonfire() {
    return {
      raised: 1186,
      sold: 87,
      supporters: 52,
      recurringSupporters: 0,
      netNewSupporters: 'unknown',
      percentage: 10 / 30,
      image: 'https://i.imgur.com/T4Zdij9.png',
      likes: 85,
      reach: 93600, // 93632,
      clicks: 1058,
      impressions: 387900, // 387919,
      frequency: 387919 / 93632,
      ctr: 0.27,
    }
  }

  async conversionFunnel() {
    return REST.post('campaign/funnel', {
      ID: 1,
    }).then(data => {
      const sources = {
        ad: {
          key: 2,
          name: 'Ad Traffic',
          data: [],
        },
        organic: {
          key: 1,
          name: "Out Youth's posts",
          data: [],
        },
        other: {
          key: 1,
          name: 'Friend Referral',
          data: [],
        },
        total: {
          key: 1,
          name: 'All Traffic',
          data: [],
        },
      }
      _.forEach(
        ['ViewContent', 'AddToCart', 'InitiateCheckout', 'Purchase'],
        event => {
          _.forEach(data[event], (people, source) => {
            sources[source].data.push(people)
          })
        }
      )
      return {
        series: [sources.total, sources.ad, sources.organic, sources.other],
        axes: {
          xAxis: {
            type: 'string',
            categories: [
              'Viewed Product',
              'Added to Cart',
              'Begun Checking Out',
              'Orders Made',
            ],
          },
          yAxis: {
            title: {
              text: 'Number of People',
            },
          },
        },
      }
    })
  }

  async dailyBreakdown() {
    return REST.post('campaign/breakdown/daily', {
      ID: 1,
      clampDates: true,
      groupNonOrganic: true,
    }).then(data => {
      const axes = {
        xAxis: {
          type: 'date',
          categories: [],
        },
        yAxis: {
          title: {
            text: 'Total Supporters',
          },
        },
      }
      const organic = {
        name: 'On their own',
        data: [],
      }
      const madetouplift = {
        name: 'With Made to Uplift',
        data: [],
      }

      _.forEach(data, obj => {
        axes.xAxis.categories.push(`Day ${DayNum(obj.date)}`)
        organic.data.push(obj.organic)
        madetouplift.data.push(obj.madetouplift)

        this.orders.nonprofit += obj.organic
        this.orders.madetouplift += obj.madetouplift
      })

      const newDailyChart = {
        axes: axes,
        series: [organic, madetouplift],
      }

      return newDailyChart
    })
  }

  get madetouplift() {
    return {
      image: 'https://i.imgur.com/0icFslC.png',
      likes: 3921,
      reach: 82900, // 82928
      impressions: 164000, // 164078,
      frequency: 164078 / 82928,
      ctr: 1.01,
    }
  }

  normalized(type, data) {
    let min = 0
    let max = 0

    if (type === 'views') {
      max = 450000
      return {
        bonfire: normalize(this.bonfire.impressions, min, max),
        madetouplift: normalize(this.madetouplift.impressions, min, max),
      }
    } else if (type === 'likes') {
      max = 5000
      return {
        bonfire: normalize(this.bonfire.likes, min, max),
        madetouplift: normalize(this.madetouplift.likes, min, max),
      }
    } else if (type === 'ctr') {
      max = 2
      return {
        bonfire: normalize(this.bonfire.ctr, min, max),
        madetouplift: normalize(this.madetouplift.ctr, min, max),
      }
    } else if (type === 'supporters') {
      max = 300
      return {
        bonfire: normalize(this.bonfire.supporters, min, max),
        madetouplift: normalize(data, min, max),
      }
    } else if (type === 'sold') {
      max = 650
      return {
        bonfire: normalize(this.bonfire.sold, min, max),
        madetouplift: normalize(data, min, max),
      }
    } else if (type === 'proceeds') {
      max = 3000
      return {
        bonfire: normalize(this.bonfire.raised, min, max),
        madetouplift: normalize(data, min, max),
      }
    }
  }
}
