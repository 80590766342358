import { merge } from 'lodash'
import React from 'react'
import BaseOptionChart from './baseOptions'

import dynamic from 'next/dynamic'
const ApexCharts = dynamic(() => import('react-apexcharts'), { ssr: false })

// ----------------------------------------------------------------------

export default function ChartRadialBar({ labels, data, summary }) {
  const GraphColor = ['#014C8F', '#4E5FD0', '#9992E0', '#FFB6B9']

  const chartOptions = merge(BaseOptionChart(), {
    labels: labels,
    colors: GraphColor,
    fill: {
      type: 'solid',
    },
    legend: {
      horizontalAlign: 'center',
    },
    plotOptions: {
      radialBar: {
        hollow: { size: '40%', background: 'transparent' },
        track: {
          background: '#dce4e6',
        },
        dataLabels: {
          value: { offsetY: 16 },
          name: {
            fontSize: '20px',
            fontColor: GraphColor,
          },
          value: {
            fontSize: '17px',
            formatter: function(val) {
              return val
            },
          },
          total: {
            show: false,
          },
        },
      },
    },
  })

  return (
    <ApexCharts
      type="radialBar"
      series={data}
      options={chartOptions}
      height={350}
    />
  )
}
