import _ from 'lodash'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { FormLabel, Grid, Typography } from '@material-ui/core'

import Colors from '../utils/colors'

const useStyles = makeStyles(theme => ({
  box: {
    flex: 0,
    border: props => `1px solid ${Colors.get(props.color)}`,
    borderRadius: props => (props.ignoreStyle ? 0 : 8),
    paddingLeft: props => (props.ignoreStyle ? 0 : theme.spacing(2)),
    paddingRight: props => (props.ignoreStyle ? 0 : theme.spacing(2)),
    backgroundColor: 'white',
    margin: props => (props.ignoreStyle ? 0 : 5),
  },
  label: {
    marginTop: props => (props.ignoreStyle ? 0 : -7.5),
    marginLeft: props => (props.ignoreStyle ? 0 : 10),
    marginRight: props => (props.ignoreStyle ? 0 : 10),
    width: 'initial',
    color: props => Colors.get(props.color),
    backgroundColor: 'white',
    paddingLeft: props => (props.ignoreStyle ? 0 : theme.spacing(0.5)),
    paddingRight: props => (props.ignoreStyle ? 0 : theme.spacing(0.5)),
    whiteSpace: 'nowrap',
    borderRadius: props => (props.ignoreStyle ? 0 : 30),
  },
  data: {
    flexFlow: 'row',
    justifyContent: 'space-evenly',
  },
  notes: {
    color: Colors.get('grey', 700),
    whiteSpace: 'nowrap',
  },
}))

export default function TitledBox(props) {
  const classes = useStyles(props)
  const { children, className, notes, title } = props

  return (
    <Grid container className={className ? className : classes.box}>
      <FormLabel component="legend" className={classes.label}>
        {title}
      </FormLabel>
      <Grid container className={classes.data}>
        {children}
      </Grid>
      <Grid container justify="center">
        {_.map(notes, (note, idx) => (
          <Typography key={idx} variant="caption" className={classes.notes}>
            {note}
          </Typography>
        ))}
      </Grid>
    </Grid>
  )
}
