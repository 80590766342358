import _ from 'lodash'
import React from 'react'
import { withSnackbar } from 'notistack'
import { Link } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'

import Page from '../page'
import Row from '../../components/row'
import Testimonial from '../../components/testimonial'
//import BarComparison from '../../components/charts/barComparison'
import Radial from '../../components/charts/radial'
import ChartArea from '../../components/charts/area'
import ChartColumnStacked from '../../components/charts/columnStacked'
import IconButton from '../../components/icon_button'
import Impact from '../../components/contextResultsCase'
import { IconWrapper } from '../../components/icon'
import { formatNumber } from '../../utils/core'
import OutYouthCaseStudy from '../../backend/case-studies/outyouth'
import Colors from '../../utils/colors'

const useKTStyles = makeStyles(theme => ({
  keyTakeaway: {
    flexFlow: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1.5),
  },
  keyTakeawayIcon: {
    marginRight: theme.spacing(1),
    '&::after': {
      position: 'absolute',
      height: 24,
      width: 24,
      borderRadius: '50%',
      animation: '$pulse-animation 2s infinite',
      border: 0,
      content: '""',
    },
  },
  '@keyframes pulse-animation': {
    '0%': {
      boxShadow: '0 0 0 0px rgba(0, 0, 0, 0.2)',
    },
    '100%': {
      boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
    },
  },
}))

function KeyTakeaway({ children }) {
  const classes = useKTStyles()

  return (
    <Grid container className={classes.keyTakeaway}>
      <IconButton
        icon={new IconWrapper('lightbulb')}
        color={Colors.get('yellow', 'A700')}
        className={classes.keyTakeawayIcon}
      />
      {children}
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  backgroundStyle: {
    backgroundImage: 'url(https://i.imgur.com/TDrhSIO.png)',
    backgroundPosition: 'cover',
    //backgroundImage: 'linear-gradient(0.5turn, #94ccd6, #F5D3B7, transparent)',
    width: '100%',
  },
  campaignLink: {
    color: '#4E5FD0',
  },
  hero: {
    padding: theme.spacing(7),
    display: 'flex',
    flexFlow: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  heroHeader: {
    fontSize: 'xxx-large',
    maxWidth: '700px',
    textAlign: 'center',
    fontFamily: 'Rubik',
    fontWeight: 'light',
  },
  context: {
    display: 'flex',
    flexWrap: 'wrap',
    flexFlow: 'row',
    textAlign: 'start',
    backgroundColor: '#fcfeff',
    marginTop: theme.spacing(2),
    //marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    justifyContent: 'space-around',
    width: '100vw',
    boxShadow: '0 0 15px 8px rgba(0, 0, 0, 0.1)',
  },
  contextText: {
    maxWidth: '600px',
    fontFamily: 'Rubik',
  },
  contextImgDetails: {
    display: 'flex',
    flexWrap: 'wrap',
    flexFlow: 'column',
    alignItems: 'center',
  },
  contextProjectDetails: {
    textAlign: 'center',
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    fontFamily: 'Karla',
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'row',
    },
  },
  contextDetailGroup: {
    display: 'flex',
    flexFlow: 'column',
    flexWrap: 'wrap',
    margin: theme.spacing(1),
  },
  contextDetailTitle: {
    fontWeight: 'bold',
    fontFamily: 'Rubik',
    textDecorationLine: 'underline',
    fontVariant: 'small-caps',
  },
  contextDetailSubtitle: {
    fontFamily: 'Rubik',
  },
  objectiveIcon: {
    margin: theme.spacing(1.5),
  },
  bold: {
    fontWeight: 'bold',
    fontFamily: 'Rubik',
  },
  chart: {
    margin: theme.spacing(1),
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  impact: {
    display: 'flex',
    flexFlow: 'row',
  },
  mainCards: {
    backgroundColor: '#EBF5F8',
    boxShadow: 'none',
    marginTop: theme.spacing(5),
    justifyContent: 'center',
  },
  allResultsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexFlow: 'Row',
    justifyContent: 'center',
  },
  radialGraphs: {
    justifyContent: 'space-around',
    display: 'flex',
    flexWrap: 'wrap',
  },
  skeleton: {
    maxWidth: '500px',
    justifyContent: 'center',
    maxHeight: '100px',
    variant: 'rect',
  },
  resultsTitle: {
    fontWeight: 'bold',
    fontSize: 'x-large',
    justifyContent: 'center',
  },
  resultsCard: {
    display: 'flex',
    flexFlow: 'wrap',
    maxWidth: '425px',
    backgroundColor: '#fcfeff',
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    borderRadius: 20,
    boxShadow: '0 0 20px 1px rgba(194, 235, 255, 0.3)',
  },
  testimonial: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'center',
    maxWidth: '1000px',
    backgroundColor: '#fcfeff',
    margin: theme.spacing(1),
    borderRadius: 20,
    boxShadow: '0 0 20px 1px rgba(194, 235, 255, 0.3)',
  },
}))

const caseStudy = new OutYouthCaseStudy()
function CaseStudy() {
  const classes = useStyles()

  const [proceeds, setProceeds] = React.useState()
  const [sold, setSold] = React.useState()
  const [supportersAll, setSupportersAll] = React.useState()
  const [supportersNew, setSupportersNew] = React.useState()
  const [supportersOrganic, setSupportersOrganic] = React.useState()
  const [supportersRecurring, setSupportersRecurring] = React.useState()

  const [conversionFunnelChart, setConversionFunnelChart] = React.useState({})
  const [dailyChart, setDailyChart] = React.useState({})

  React.useEffect(() => {
    caseStudy.dailyBreakdown().then(data => {
      setDailyChart(data)
    })
    caseStudy.conversionFunnel().then(setConversionFunnelChart)
    caseStudy.attribution().then(data => {
      console.log(data)
      setProceeds(_.get(data, ['total', 'proceeds']))
      setSold(_.get(data, ['total', 'sold']))
      setSupportersAll(_.get(data, ['total', 'people']))
      setSupportersOrganic(_.get(data, ['organic', 'people']))
      setSupportersRecurring(_.get(data, ['total', 'recurring']))

      const ad = _.get(data, ['ad', 'people'], 0)
      const wom = _.get(data, ['word_of_mouth', 'people'], 0)
      const lib = _.get(data, ['link_in_bio', 'people'], 0)
      setSupportersNew(ad + wom + lib)
    })
  }, [])

  const cards = [
    {
      objective: 1,
      id: 'creative_one',
      title: 'Creatives that Inspire',
      subtitle:
        "We help you design creatives that resonate with new audiences if you don't have the bandwidth or resources",
      content: (
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  {supportersAll && sold && proceeds ? (
                    <Row className={classes.radialGraphs}>
                      <Radial
                        labels={[
                          'Ad Views',
                          'Ad Likes',
                          'Supporters',
                          'Raised',
                        ]}
                        data={[
                          caseStudy.bonfire.impressions,
                          caseStudy.bonfire.likes,
                          caseStudy.bonfire.supporters,
                          caseStudy.bonfire.raised,
                        ]}
                      />
                      <Radial
                        labels={[
                          'Ad Views',
                          'Ad Likes',
                          'Supporters',
                          'Raised',
                        ]}
                        data={[
                          caseStudy.madetouplift.impressions,
                          caseStudy.madetouplift.likes,
                          supportersAll,
                          proceeds,
                        ]}
                      />
                    </Row>
                  ) : (
                    <Skeleton variant="rect" className={classes.skeleton} />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ),
      keyTakeaway: (
        <Typography variant="subtitle1">
          We showed our ad <i>fewer than half</i> as many times compared to a
          previous campaign, but got a<b>46X higher engagement rate</b> than Out
          Youth achieved on their own.
          <br />
          Resulting in us tapping into over <b>4X</b> as many financial
          supporters with over <b>twice</b> the amount of proceeds raised.
          <br />
          The cherry on top? We delivered a creative that resonated with users
          and provided shipping fulfillment{' '}
          <u>
            <b>at no cost</b>
          </u>
          .
        </Typography>
      ),
    },
    {
      objective: 2,
      id: 'boost_two',
      title: 'A Measurable Boost',
      subtitle:
        'We help you optimize for people most likely to purchase and incentivize growth via social network effects',
      content: (
        <ChartColumnStacked series={dailyChart.series} axes={dailyChart.axes} />
      ),
      keyTakeaway:
        supportersOrganic !== null && supportersNew !== null ? (
          <Typography variant="subtitle1">
            On their own, Out Youth was able to reach{' '}
            <b>{formatNumber(supportersOrganic)} unique supporters.</b> We
            helped them reach an additional{' '}
            <u>
              <b>{formatNumber(supportersNew)} people!</b>
            </u>
          </Typography>
        ) : null,
    },
    {
      objective: 3,
      id: 'journey_three',
      title: 'Full Customer Journey',
      subtitle:
        'Instead of only displaying the final number of purchases, we provide the full conversion funnel to see how well each traffic source performed',
      content: (
        <ChartArea
          series={conversionFunnelChart.series}
          axes={conversionFunnelChart.axes}
        />
      ),
      keyTakeaway: (
        <Typography variant="subtitle1">
          Previously, Out Youth didn't know how effective their social media
          campaigns were. We provided that clarity for the first time: while
          most e-commerce ads have a conversion rate of ~1-2%,{' '}
          <b>
            <u>we achieved a &gt;8% conversion rate.</u>
          </b>
        </Typography>
      ),
    },
    {
      title: 'All Results',
      subtitle: 'Comparing two Pride Month campaigns',
      content: (
        <Grid className={classes.allResultsContainer}>
          <Card className={classes.resultsCard}>
            <Row className={classes.resultsTitle}>
              <Typography className={classes.resultsTitle}>
                Pride Month 2020
              </Typography>
            </Row>
            <Row>
              {/* <Typography className={classes.bold}>Creative: &nbsp;</Typography> */}
              <Grid>
                <img
                  alt="bonfire tshirt"
                  className={classes.image}
                  src={caseStudy.bonfire.image}
                />
              </Grid>
            </Row>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.bold}>Platform</TableCell>
                    <TableCell>Bonfire</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>Designer</TableCell>
                    <TableCell>An external, paid designer</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>Ad Views</TableCell>
                    <TableCell>
                      {formatNumber(caseStudy.bonfire.impressions)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>Ad Likes</TableCell>
                    <TableCell>
                      {formatNumber(caseStudy.bonfire.likes)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>CTR</TableCell>
                    <TableCell>
                      {formatNumber(caseStudy.bonfire.ctr, {
                        includeDecimal: true,
                      })}
                      %
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>Merch sold</TableCell>
                    <TableCell>
                      {formatNumber(caseStudy.bonfire.sold)} shirts @ $30/each
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>Raw Sale</TableCell>
                    <TableCell>
                      {formatNumber(caseStudy.bonfire.sold * 30, {
                        isCurrency: true,
                      })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>Proceeds</TableCell>
                    <TableCell>
                      {formatNumber(caseStudy.bonfire.raised, {
                        isCurrency: true,
                      })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>Supporters</TableCell>
                    <TableCell>
                      {formatNumber(caseStudy.bonfire.supporters)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>
                      Net New Supporters
                    </TableCell>
                    <TableCell>{caseStudy.bonfire.netNewSupporters}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>
                      Monthly Supporters
                    </TableCell>
                    <TableCell>
                      {formatNumber(caseStudy.bonfire.recurringSupporters)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          {/* //vs tag */}
          <Card className={classes.resultsCard}>
            <Row className={classes.resultsTitle}>
              <Typography className={classes.resultsTitle}>
                Pride Month 2021
              </Typography>
            </Row>
            <Row>
              {/* <Typography className={classes.bold}>Creative:&nbsp;</Typography> */}
              <Grid>
                <img
                  alt="made to uplift sticker design"
                  className={classes.image}
                  src={caseStudy.madetouplift.image}
                />
              </Grid>
            </Row>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.bold}>Platform</TableCell>
                    <TableCell>Made to Uplift (us!)</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>Designer</TableCell>
                    <TableCell>Us, at no cost</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>Ad Views</TableCell>
                    <TableCell>
                      {formatNumber(caseStudy.madetouplift.impressions)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>Ad Likes</TableCell>
                    <TableCell>
                      {formatNumber(caseStudy.madetouplift.likes)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>CTR</TableCell>
                    <TableCell>
                      {formatNumber(caseStudy.madetouplift.ctr, {
                        includeDecimal: true,
                      })}
                      % (industry standard)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>Merch sold</TableCell>
                    <TableCell>
                      {formatNumber(sold)} stickers @ $5/each
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>Raw Sale</TableCell>
                    <TableCell>
                      {formatNumber(sold * 5, { isCurrency: true })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>Proceeds</TableCell>
                    <TableCell>
                      {formatNumber(proceeds, { isCurrency: true })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>Supporters</TableCell>
                    <TableCell>{formatNumber(supportersAll)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>
                      Net New Supporters
                    </TableCell>
                    <TableCell>{formatNumber(supportersNew)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.bold}>
                      Monthly Supporters
                    </TableCell>
                    <TableCell>{formatNumber(supportersRecurring)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      ),
      keyTakeaway: (
        <Typography variant="subtitle1">
          We focus on small, collective donations to reach a greater pool of
          potential long-term supporters.
        </Typography>
      ),
    },
    {
      title: 'Testimonial',
      content: <Testimonial />,
    },
  ]
  function content() {
    return (
      <Grid container className={classes.container}>
        <Grid className={classes.backgroundStyle}>
          <Grid container className={classes.hero}>
            <Typography className={classes.heroHeader}>
              Tapping into new supporters for Out Youth using data-driven
              analytics
            </Typography>
          </Grid>
          <Grid container className={classes.context}>
            <Grid className={classes.contextText}>
              <Grid item>
                In June 2021, we launched our first campaign to support Out
                Youth, an LGBT non-profit that serves youth.
              </Grid>
              <br />
              <Grid item>We supported Out Youth by:</Grid>
              <br />
              <Grid item>
                <ul>
                  <li>
                    Selling low cost stickers to reduce barrier of support.
                  </li>
                  <li>
                    Donating 100% of proceeds to Out Youth, with strong margins
                    per sticker.
                  </li>
                  <li>
                    Fulfillment and shipping handled by us, all at no cost. Out
                    Youth only needed to post to their social media
                  </li>
                </ul>
              </Grid>
              <br />
              <Grid item>The results of the Campaign:</Grid>
              <Impact className={classes.impact} />
              <br />
              <Grid item>We had 3 goals going into Pride Month:</Grid>
              <br />
              <Grid item>
                <Link to="#creative_one">
                  <IconButton icon={new IconWrapper(1)} color="blueGrey" />
                </Link>
                Have a brand-focused creative that would excite new supporters
              </Grid>
              <Grid item>
                <Link to="#boost_two">
                  <IconButton icon={new IconWrapper(2)} color="blueGrey" />
                </Link>
                Reach new long-term supporters
              </Grid>
              <Grid item>
                <Link to="#journey_three">
                  <IconButton icon={new IconWrapper(3)} color="blueGrey" />
                </Link>
                Measure how successful our campaign efforts were
              </Grid>
            </Grid>
            <Grid className={classes.contextImgDetails}>
              <Grid className={classes.contextProjectDetails}>
                <Grid className={classes.contextDetailGroup}>
                  <Typography className={classes.contextDetailTitle}>
                    Client
                  </Typography>
                  <Typography className={classes.contextDetailSubtitle}>
                    Out Youth
                  </Typography>
                </Grid>
                <Grid className={classes.contextDetailGroup}>
                  <Typography className={classes.contextDetailTitle}>
                    Link
                  </Typography>
                  <Typography className={classes.contextDetailSubtitle}>
                    <a
                      href="https://madetouplift.com/campaign/?id=1"
                      target="_blank"
                      rel="noreferrer"
                      className={classes.campaignLink}
                    >
                      Campaign
                    </a>
                  </Typography>
                </Grid>
                <Grid className={classes.contextDetailGroup}>
                  <Typography className={classes.contextDetailTitle}>
                    Tools
                  </Typography>
                  <Typography className={classes.contextDetailSubtitle}>
                    Facebook Ads
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <img
                  alt="made to uplift sticker design"
                  className={classes.image}
                  src={caseStudy.madetouplift.image}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {_.map(cards, (card, idx) => (
          <Grid item key={idx} xs={12} md={6} className={classes.chart}>
            <Card className={classes.mainCards}>
              <div id={card.id}>
                <CardHeader
                  title={card.title}
                  subheader={card.subtitle}
                  action={
                    card.objective ? (
                      <IconButton
                        className={classes.objectiveIcon}
                        icon={new IconWrapper(card.objective)}
                      />
                    ) : null
                  }
                />
              </div>
              <CardContent dir="ltr">
                {card.content ? (
                  card.content
                ) : (
                  <Skeleton variant="rect" className={classes.skeleton} />
                )}
                {card.keyTakeaway ? (
                  <KeyTakeaway>{card.keyTakeaway}</KeyTakeaway>
                ) : null}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    )
  }

  return <Page content={content()} />
}

export default withSnackbar(CaseStudy)
