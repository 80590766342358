import { get, merge } from 'lodash'
import React from 'react'
import { Skeleton } from '@material-ui/lab'
import BaseOptionChart from './baseOptions'

import dynamic from 'next/dynamic'
const ApexCharts = dynamic(() => import('react-apexcharts'), { ssr: false })

// ----------------------------------------------------------------------

export default function ChartColumnStacked({ series, axes }) {
  const GraphColor = ['#014C8F', '#FFB6B9']
  const xAxis = get(axes, 'xAxis')
  const chartOptions = merge(BaseOptionChart(), {
    chart: { stacked: true, zoom: { enabled: true } },
    colors: GraphColor,
    fill: {
      type: 'solid',
    },
    legend: {
      itemMargin: { vertical: 8 },
      position: 'bottom',
    },
    plotOptions: { bar: { columnWidth: '75px', borderRadius: 4 } },
    stroke: { show: false },
    xaxis: {
      categories: [
        'Day 1',
        'Day 2',
        'Day 3',
        'Day 4',
        'Day 5',
        'Day 6',
        'Day 7',
        'Day 8',
        'Day 9',
        'Day 10',
        'Day 11',
        'Day 12',
        'Day 13',
        'Day 14',
        'Day 15',
        'Day 16',
        'Day 17',
        'Day 18',
        'Day 19',
        'Day 20',
        'Day 21',
        'Day 22',
        'Day 23',
      ],
      labels: {
        show: true,
        rotate: -90,
        rotateAlways: true,
      },
    },

    yaxis: get(axes, 'yAxis'),
  })

  if (series && axes) {
    return (
      <ApexCharts
        type="bar"
        series={series}
        options={chartOptions}
        height={320}
      />
    )
  }
  return <Skeleton variant="rect" width={300} height={200} />
}
