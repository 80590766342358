import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  box: {
    borderRadius: 8,
    padding: theme.spacing(1),
    minWidth: 50,
    minHeight: 50,
    textAlign: 'center',
    height: 'fit-content',
  },
  title: {
    fontSize: 'large',
    fontWeight: 500,
    fontFamily: 'Rubik',
  },
  subtitle: {
    fontSize: 'medium',
    fontFamily: 'Karla',
  },
}))

export default function SimpleBox({ title, subtitle, border, onClick }) {
  const classes = useStyles()

  return (
    <Box
      className={classes.box}
      style={{ border: border ? '1px solid lightgray' : 'none' }}
      onClick={onClick}
    >
      <Grid item>
        <Typography className={classes.title}>{title}</Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.subtitle}>{subtitle}</Typography>
      </Grid>
    </Box>
  )
}
