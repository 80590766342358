import { get, merge } from 'lodash'
import React from 'react'
import { Skeleton } from '@material-ui/lab'
import BaseOptionChart from './baseOptions'

import dynamic from 'next/dynamic'
const ApexCharts = dynamic(() => import('react-apexcharts'), { ssr: false })

// ----------------------------------------------------------------------
export default function ChartArea({ series, axes }) {
  const GraphColor = ['#014C8F', '#4E5FD0', '#9992E0', '#FFB6B9']
  const chartOptions = merge(BaseOptionChart(), {
    xaxis: get(axes, 'xAxis'),
    yaxis: get(axes, 'yAxis'),
    colors: GraphColor,
  })

  if (series && axes)
    return (
      <ApexCharts
        type="area"
        series={series}
        options={chartOptions}
        height={320}
      />
    )
  return <Skeleton variant="rect" width={300} height={200} />
}
